<template>
  <v-dialog
      v-if="returnDialog.visible"
      v-model="returnDialog.visible"
      :color="returnDialog.type"
      persistent
      max-width="600"
      transition="fade-transition"
  >
<!--    bigger -->
    <v-card class="pt-5">
      <v-img class="mt-5 mb-5 mx-auto" :src="defaultThumbUrl" v-if="defaultThumbUrl" contain max-width="100" max-height="100"></v-img>
      <v-card-title>
        <span class="mx-auto">EAN: {{ currentArticle.item.product.ean }}</span>
      </v-card-title>
      <!--      <v-divider></v-divider>-->
      <v-card-subtitle class="mt-5 text-body-1">{{ currentArticle.item.product.title }}</v-card-subtitle>

      <v-divider></v-divider>

      <v-card-text>
        <div class="text-subtitle-1 mt-5 mb-3 font-weight-bold">Retoure-Grund auswählen</div>

        <div v-if="!amazonReturnReason">
          <v-select
              :items="returnReasons"
              label="Retoure-Grund"
              v-model="selectedReason"
              item-value="code"
              item-text="text"
              outlined
              height="75"
          ></v-select>
        </div>
        
        <div class="text-body-1 mb-5" v-if="amazonReturnReason">Retoure-Grund: {{ amazonReturnReason }}</div>

        <div class="text-subtitle-1 mt-2 mb-2 font-weight-bold">Ist der Artikel verkaufsfähig?</div>

        <v-btn-toggle class="mt-3" variant="outlined" x-large v-model="articleResalable">
          <v-btn color="red" x-large @click="processNotResalable">NEIN</v-btn>
          <v-btn color="green" class="ml-5" x-large >JA</v-btn>
        </v-btn-toggle>


        <div v-if="this.currentArticle.item.product.returnIndication">
          <v-divider class="mt-5"/>

          <div class="returnIndication">
            <div class="text-h6 mb-2 font-weight-bold">Retoure-Hinweis</div>
            <div class="text-body-1">{{ this.currentArticle.item.product.returnIndication }}</div>
          </div>
        </div>
      </v-card-text>

      <v-divider/>
      <v-card-actions>
        <v-btn
            :disabled="!valid"
            class="no-text-transform btn__p100"
            color="primary darken-1"
            text
            @click="onCallback"
        >
         <span style="white-space: normal;">
           OK
         </span>
        </v-btn>
        <v-btn
            class="no-text-transform btn__p100"
            color="red"
            text
            @click="onCancelCallback"
        >
         <span style="white-space: normal;">
           Abbrechen
         </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import util from "@/util/util";

export default {
  name: "ReturnDialog",
  data() {
    return {
      rules: [
        v => !!v || "Required",
        // () => !!this.articleResalable || "Required"
      ],
      selectedReason: null,
      articleResalable: null
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    returnDialog() {
      return this.$store.state.returnDialog
    },
    currentArticle() {
      return this.$store.state.currentArticleToReturn
    },
    defaultThumbUrl() {
      const images = this.currentArticle.item.product.images
      let defaultImage = images.find(i => i.default);
      if (!defaultImage) return null
      return defaultImage.url
    },
    getColor() {
      if (this.returnDialog.type === "error") {
        return "red darken-1"
      }

      return "green darken-1"
    },
    returnReasons() {
      return util.returnReasons
    },
    valid() {
      return this.articleResalable !== null && (this.selectedReason !== null || this.amazonReturnReason !== null)
    },
    amazonReturnReason() {
      if (this.order.sellingPlatform === "Amazon") {
        let amazonReturnReasonsPerSku = this.order.amazonReturnReasonsPerSku;
        return amazonReturnReasonsPerSku[this.currentArticle.item.product.sku]
      }
      return null
    }
  },
  methods: {
    onCallback() {
      const self = this
      let articleResalable = this.articleResalable !== 0
      let articleToReturn = {
        returnReason: this.selectedReason,
        articleResalable: articleResalable,
        ean: this.currentArticle.item.product.ean,
        sku: this.currentArticle.item.product.sku,
        quantity: 1,
        timestamp: new Date().toISOString(),
        amazonReturnReason: this.amazonReturnReason
      }

      if (this.selectedReason === "DefekterArtikel" && articleResalable === "JA") {
        this.$store.dispatch("showInfoConfirm",
            {
              text: "Sicher verkaufsfähig?",
              buttonText: "OK",
              callback: function () {
                self.completeArticleToReturn(articleToReturn)
              },
              cancelButtonText: "Abbrechen",
              cancelButtonCallback: function () {
                self.reset()
              }
            })
      } else {
        self.completeArticleToReturn(articleToReturn)
      }
    },
    completeArticleToReturn(articleToReturn) {
      this.$store.commit("addArticleToReturn", articleToReturn)
      this.reset()
    },
    onCancelCallback() {
      this.closeDialog()
      this.reset()
    },
    closeDialog() {
      this.$store.commit('hideReturnDialog')
    },
    processNotResalable() {
      this.$store.dispatch("showInfoConfirm",
          {
            text: "Problemretoure-Prozess durchführen",
            buttonText: "OK",
            callback: function () {
            }
          })
    },
    reset() {
      this.closeDialog()
      this.$store.commit('setCurrentArticleToReturn', null)
      this.articleResalable = null
      this.selectedReason = null
      this.$emit('dialogClosed')
    }
  }
}
</script>

<style scoped>
.button-yes {
  color: green;
}

.button-no {
  color: red;
}

.returnIndication {
  border: 1px !important;
  border-color: red;
  background-color: #ff5252;
  padding: 5px;
}
</style>