import Vue from 'vue'
import VueRouter from "vue-router";
import ReturnHome from "@/components/ReturnHome.vue";
import OrderNumberEntry from "@/components/OrderNumberEntry";
import ArticleScan from "@/components/ArticleScan";
import LoginHome from "@/components/LoginHome";
import axios from "@/plugins/axios";


Vue.use(VueRouter);

const routes = [
    { path: '/home', component: ReturnHome },
    { path: '/login', component: LoginHome },
    { path: '/retoure', component: OrderNumberEntry },
    { path: '/retoure/:billBeeOrderId', component: ArticleScan },
    { path: '/', redirect: '/home' }
];

const router =  new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
});

router.beforeEach(function (to, from, next) {
    if (to.path !== '/login') {
        axios
            .get("/api/login/loggedIn", {withCredentials: true})
            .then(() => next())
            .catch(() => next({path: '/login?forward=' + to.path}))
    } else next()
})

export default router;

export const useRouter = () => router