<template>
  <v-main>
    <v-container class="pa-8" fluid>
      <v-row class="mb-1">
        <v-col cols="12">
          <div class="text-h4 transition-swing" v-text="'Billbee OrderID / Bestellnummer eingeben/scannen'"></div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-form ref="billBeeOrderIdEntry" v-model="valid" v-on:submit.prevent="noop">
          <v-col class="flex-grow-1">
            <v-text-field
                v-model="billBeeOrderId"
                ref="billBeeOrderId"
                clearable
                dense
                outlined
                inputmode="numeric"
                :label="'Billbee OrderID / Bestellnummer'"
                :rules="entryRules"
                @keyup.enter="orderScanned(billBeeOrderId)"></v-text-field>
          </v-col>
        </v-form>
        <v-col>
          <v-btn color="secondary" tile :disabled="!valid" @click="orderScanned(billBeeOrderId)">Suchen</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>


export default {
  name: "OrderNumberEntry",
  components: {
  },
  data() {
    return {
      billBeeOrderId: "",
      valid: false,
      entryRules: [
        v => !!v || "Required",
      ]
    }
  },
  mounted() {
    this.$refs["billBeeOrderId"].focus();
    this.$store.commit('clearArticlesToReturn')
  },
  computed: {

  },
  methods: {
    orderScanned(billBeeOrderId) {
      if (process.env.VUE_APP_DEBUG_MODE) console.log("Order: " + billBeeOrderId)
      const self = this
      this.$store.dispatch('loadOrder', billBeeOrderId).then(function () {
        self.$router.push("/retoure/" + billBeeOrderId)
      }).catch(function() {})
    },
    noop() {

    }
  }
}
</script>

<style scoped>

</style>