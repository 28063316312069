<template>
  <v-main class="mt-10 mr-5">
    <v-row class="ml-5">
      <div class="text-h4" v-text="order.orderNumber"></div>
    </v-row>
    <v-row class="ml-5">
      <v-chip small color="primary">{{ order.sellingPlatform }}</v-chip>
    </v-row>

    <ArticleTable/>

    <v-divider class="mt-6 fat-divider" />

    <v-row class="ml-3 mt-3">
      <v-col xs="12" sm="6" md="6" lg="4" xl="4">
        <v-card>
          <v-card-title>
            <div class="primary--text">Artikel scannen</div>
          </v-card-title>
          <v-row class="ml-2 mr-2 mt-1">
            <v-col>
              <v-form ref="articleNumberEntry" v-model="valid" v-on:submit.prevent="noop">
                <v-text-field
                    v-model="articleNumber"
                    ref="articleNumber"
                    clearable
                    dense
                    outlined
                    inputmode="numeric"
                    :label="'EAN'"
                    :rules="entryRules"
                    @keyup.enter="articleEntered(articleNumber)"></v-text-field>
              </v-form>
            </v-col>

            <v-col>
              <v-btn color="secondary" tile :disabled="!valid" block large
                     @click="articleEntered(articleNumber)">Suchen
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="ml-5 mt-7">
      <span class="text-h6 title">Erfasste Retoure</span>
    </v-row>

    <v-row class="ml-0">
      <ReturnOverviewTable :items="articlesToReturn"/>
    </v-row>

    <v-row class="ml-0">
      <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="3">
        <v-container fill-height>
          <v-btn :disabled="!anyArticleReturned" color="primary" x-large :height="75" block @click="confirmDispatch">
                        <span class="ma-2 text-wrap"
                              style="width: min-content; margin: auto;">Retoure buchen</span>
          </v-btn>
        </v-container>
      </v-col>
    </v-row>

    <ReturnDialog v-on:dialogClosed="focusOnArticleNumber"/>

  </v-main>
</template>

<script>
import ArticleTable from "@/components/ArticleTable";
import articleLocator from "@/util/articleLocator";
import ReturnOverviewTable from "@/components/ReturnOverview.vue";
import ReturnDialog from "@/components/ReturnDialog.vue";

export default {
  name: "OrderDispatch",
  components: {ReturnDialog, ReturnOverviewTable, ArticleTable},
  data() {
    return {
      articleNumber: "",
      valid: false,
      entryRules: [
        v => !!v || "Required",
      ]
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    orderItems() {
      let result = []
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i];
        result.push({
          quantity: orderItem.quantity,
          ean: orderItem.product.ean,
          title: orderItem.product.title,
          status: "x",
          returnedQuantity: orderItem.returnedQuantity
        })
      }
      return result
    },
    totalQuantity() {
      let totalAmount = 0;
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i]
        totalAmount += orderItem.quantity
      }
      return totalAmount
    },
    articlesToReturn() {
      return this.$store.state.articlesToReturn
    },
    anyArticleReturned() {
      return this.$store.state.articlesToReturn.length > 0
    }
  },
  methods: {
    resetOrder() {
      console.log("resetOrder")
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i];
        // reset all dispatched items
        orderItem.returnedQuantity = 0
      }
    },
    articleEntered(articleNumber) {
      console.log(articleNumber)
      const paddedArticleNumber = this.padEAN(articleNumber)
      let locatedArticle = articleLocator.locateArticle(this.$store.state.order.orderItems, paddedArticleNumber);

      if (locatedArticle.code === articleLocator.results.ArticleFound) {
        this.$store.dispatch("showReturnDialog", locatedArticle)
      } else {
        this.$store.dispatch('showErrorConfirm', {
          text: "Artikel gehört nicht zur Bestellung. Bitte Problemretoure-Prozess starten.",
          buttonText: "OK",
          callback: this.noop
        })
      }

      this.articleNumber = ""
      this.$refs["articleNumber"].focus();
    },
    padEAN(ean) {
      let result = ean;
      if (ean.length < 8) {
        result = this.padToLength(ean, 8);
      } else if (ean.length > 8 && ean.length < 13) {
        result = this.padToLength(ean, 13);
      }
      return result;
    },
    padToLength(ean, length) {
      let result = ean;
      while (result.length < length) {
        result = "0" + result;
      }
      return result;
    },
    confirmDispatch() {
      const self = this
      this.$store
          .dispatch('returnOrder')
          .then(function () {
              self.$router.push("/retoure")
          })
          .catch(function () {})
    },
    focusOnArticleNumber() {
      console.log("dialog closed")
      this.$nextTick(this.$refs["articleNumber"].focus)

    },
    noop() {

    }
  },
  watch: {

  },
  mounted() {
    if (!this.$store.state.order.billBeeOrderId && this.$route.params['billBeeOrderId'] !== "undefined") {
      this.$store.dispatch("loadOrder", this.$route.params['billBeeOrderId']).catch(() => {})
    }

    if (this.$refs["articleNumber"]) this.$refs["articleNumber"].focus();
  }
}
</script>

<style scoped>
alert-icon {
  height: 100px;
}
.fat-divider {
  border-width: 2px !important;
}
</style>