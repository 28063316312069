<template>
  <v-dialog
      v-model="confirmDialog.visible"
      :color="confirmDialog.type"
      persistent
      max-width="600"
      transition="fade-transition"
  >
    <v-card>
      <v-card-title class="text-h5">
      </v-card-title>
      <v-card-text class="text-h6"><span v-html="confirmDialog.text"></span></v-card-text>
      <v-card-actions>
        <v-btn
            class="no-text-transform btn__p100"
            :color="getColor"
            text
            @click="onCallback"
        >
         <span style="white-space: normal;">
           {{ confirmDialog.buttonText }}
         </span>
        </v-btn>
        <v-btn
            class="no-text-transform btn__p100"
            :color="confirmDialog.cancelButtonColor"
            text
            v-if="confirmDialog.cancelButtonText"
            @click="onCancelCallback"
        >
         <span style="white-space: normal;">
           {{ confirmDialog.cancelButtonText }}
         </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  computed: {
    confirmDialog: {
      get() {
        return this.$store.state.confirmDialog
      }
    },
    getColor() {
      if (this.confirmDialog.type === "error")
      {
        return "red darken-1"
      }

      return "green darken-1"
    }
  },
  methods: {
    onCallback() {
      if (this.confirmDialog.callback) {
        this.confirmDialog.callback()
      }

      this.closeDialog()
    },
    onCancelCallback() {
      if (this.confirmDialog.cancelButtonCallback) {
          console.log("Calling callback " + this.confirmDialog.cancelButtonCallback)
        this.confirmDialog.cancelButtonCallback()
      }

      this.closeDialog()
    },
    closeDialog() {
      this.$store.commit('hideConfirmDialog')
    }
  }
}
</script>

<style scoped>

</style>