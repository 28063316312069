<template>
  <v-row class="ml-3 mt-5">
    <v-col sm="12" md="12" lg="10" xl="8">
      <v-data-table
          disable-pagination
          :hide-default-footer="true"
          no-data-text="Keine Artikel"
          :headers="headers"
          :items="tableItems"
          class="elevation-1"
      >
        <template v-slot:[`item.photo`]="{ item }">
          <div>
            <v-img
                v-if="item.photo"
                width="100"
                height="100"
                contain
                :src="item.photo"></v-img>
          </div>
        </template>
        <template v-slot:[`item.deletable`]="{ item }">
          <div>
            <v-btn v-if="item.deletable" icon color="red" @click="removeItem(item)"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </template>
        <template v-slot:[`item.articleResalable`]="{ item }">
          <div>
            <span :class="item.articleResalable === 'Nein' ? 'column-red' : ''"> {{ item.articleResalable }} </span>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import util from "@/util/util";

export default {
  name: "ReturnOverviewTable",
  props: ['items'],
  data() {
    return {
      headers: [
        {
          text: "Menge",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Bild",
          sortable: false,
          value: "photo"
        },
        {
          text: "Verkaufsfähig",
          sortable: false,
          value: "articleResalable"
        },
        {
          text: "Zeit",
          sortable: false,
          value: "returnTimeStamp"
        },
        {
          text: "EAN",
          sortable: false,
          value: "ean"
        },
        {
          text: "Retoure Grund",
          sortable: false,
          value: "returnReason"
        },
        {
          text: "",
          sortable: false,
          value: "deletable"
        }
      ]
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    tableItems() {
      let result = []
      for (const i in this.items) {
        const item = this.items[i]
        const orderItem = this.findOrderItem(item.ean)

        result.push({
          index: i,
          quantity: 1,
          orderNumber: this.order.orderNumber,
          ean: item.ean,
          returnReason: this.mapReason(item.returnReason) || item.amazonReturnReason,
          articleResalable: item.articleResalable ? "Ja" : "Nein",
          deletable: true,
          photo: util.getDefaultThumbUrl(orderItem.product.images),
          returnTimeStamp: this.formatTimestamp(item.timestamp)
        })
      }

      for (const i in this.order.returnedItems) {
        const item = this.order.returnedItems[i]
        const orderItem = this.findOrderItem(item.ean)

        result.push({
          index: i,
          quantity: 1,
          orderNumber: this.order.orderNumber,
          ean: item.ean,
          returnReason: this.mapReason(item.returnReason),
          articleResalable: item.articleResalable ? "Ja" : "Nein",
          deletable: false,
          photo: util.getDefaultThumbUrl(orderItem.product.images),
          returnTimeStamp: this.formatTimestamp(item.timestamp)
        })
      }

      return result
    }
  },
  methods: {
    getColor(item) {
      if (item.scanned < item.quantity) {
        return "secondary"
      } else if (item.scanned === item.quantity) {
        return "green"
      }
      return "red"
    },
    getIcon(item) {
      if (item.scanned < item.quantity) {
        return "mdi-check-circle"
      } else if (item.scanned === item.quantity) {
        return "mdi-check-circle"
      }
      return "mdi-alert-circle"
    },
    showIcon(item) {
      if (item.scanned === item.quantity
          || (item.quantity > 1 && item.scanned > 0)) {
        return true
      }
      return false
    },
    getDefaultThumbUrl(images) {
      let defaultImage = images.find(i => i.default);
      if (!defaultImage) return null
      return defaultImage.url
    },
    generateLabel(ean) {
      let payload = {ean: util.padEAN(ean), quantity: 1};
      this.$store.dispatch("createLabelAndPrint", payload)
    },
    mapReason(code) {
      let result = ""

      if (code !== null) {
        for (let i in util.returnReasons) {
          const reason = util.returnReasons[i]
          if (code === reason.code) {
            result = reason.text
            break
          }
        }
      }

      return result
    },
    findOrderItem(ean) {
      let orderItems = this.order.orderItems;
      for (const i in orderItems) {
        const item = orderItems[i]
        if (item.product.ean === ean) {
          return item
        }
      }
    },
    removeItem(item) {
      this.$store.commit('removeReturnArticle', item.index)
      let orderItem = this.findOrderItem(item.ean);
      orderItem.returnedQuantity -= 1
    },
    formatTimestamp(timestamp) {

      return new Date(timestamp).toLocaleString()
    }
  }
}
</script>

<style scoped>
.counter {
  font-size: 150%;
}

.counter-red {
  font-size: 150%;
  color: #ff5252;
  font-weight: bold;
}

.column-red {
  color: #ff5252;
  font-weight: bold;
}
</style>