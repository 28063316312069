export default {
    results: {
        ArticleFound: "ArticleFound",
        ArticleNotFound: "ArticleNotFound",
        ArticleFoundButAlreadyComplete: "ArticleFoundButAlreadyDispatched"
    },
    locateArticle(orderItems, ean) {
        let result = {
            code: this.results.ArticleNotFound
        }

        for (const i in orderItems) {
            const orderItem = orderItems[i];
            if (ean === orderItem.product.ean) {
                if (orderItem.returnedQuantity < orderItem.quantity) {
                    result = {
                        code: this.results.ArticleFound,
                        item: orderItem
                    }
                    break;
                } else if (orderItem.returnedQuantity >= orderItem.quantity) {
                    if (result.code === this.results.ArticleNotFound) {
                        // only find first invalid result
                        result = {
                            code: this.results.ArticleFoundButAlreadyComplete,
                            item: orderItem
                        }
                    }

                    // do not break yet, we might find the correct candidate
                }
            }
        }

        return result
    }
}