export default {
    returnReasons: [
        {
            code: "GefaelltNicht",
            text: "Artikel gefällt nicht"
        },
        {
            code: "ZuGross",
            text: "Zu groß"
        },
        {
            code: "ZuKlein",
            text: "Zu klein"
        },
        {
            code: "PreisLeistungsVerhaeltnis",
            text: "Preis-Leistungs-Verhältnis"
        },
        {
            code: "LieferungZuSpaet",
            text: "Lieferung zu spät"
        },
        {
            code: "AndersAlsDargestellt",
            text: "Anders als dargestellt"
        },
        {
            code: "FalscherArtikel",
            text: "Falscher Artikel"
        },
        {
            code: "DefekterArtikel",
            text: "Defekter Artikel"
        },
        {
            code: "KeineAngabe",
            text: "Keine Angabe"
        },
        {
            code: "DeliveryFailed",
            text: "Delivery Failed"
        }
    ],
    padEAN(ean) {
        let result = ean;
        if (ean.length < 8) {
            result = this.padToLength(ean, 8);
        } else if (ean.length > 8 && ean.length < 13) {
            result = this.padToLength(ean, 13);
        }
        return result;
    },
    padToLength(ean, length) {
        let result = ean;
        while (result.length < length) {
            result = "0" + result;
        }
        return result;
    },
    getDefaultThumbUrl(images) {
        let defaultImage = images.find(i => i.default);
        if (!defaultImage) return null
        return defaultImage.url
    }
}