<template>
  <v-row class="ml-3 mt-5">
    <v-col sm="12" md="12" lg="8" xl="8">
      <v-data-table
          disable-pagination
          :hide-default-footer="true"
          no-data-text="Keine Artikel"
          :headers="headers"
          :items="orderItems"
          class="elevation-1"
      >
        <template v-slot:[`item.status`]="{ item }">
          <div>
            <span v-if="item.scanned > 0" class="counter-red">R</span>
          </div>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <div>
            <span :class="item.quantity > 1 ? 'counter-red' : 'counter'"> {{ item.quantity }} </span>
          </div>
        </template>
        <template v-slot:[`item.photo`]="{ item }">
          <div>
            <v-img
                v-if="item.photo"
                width="100"
                height="100"
                contain
                :src="item.photo"></v-img>
          </div>
        </template>
        <template v-slot:[`item.generateLabelButton`]="{ item }">
          <div>
            <v-btn color="lightgray" @click="generateLabel(item.ean)">Label drucken</v-btn>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import util from "@/util/util";

export default {
  name: "ArticleTable",
  data() {
    return {
      headers: [
        {
          text: "Bestellt",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Bild",
          sortable: false,
          value: "photo"
        },
        {
          text: "EAN",
          sortable: false,
          value: "ean"
        },
        {
          text: "Bezeichnung",
          sortable: false,
          value: "title"
        },
        {
          text: "",
          sortable: false,
          value: "generateLabelButton"
        }
      ],
    }
  },
  computed: {
    order() {
      return this.$store.state.order
    },
    orderItems() {
      let result = []
      for (const i in this.$store.state.order.orderItems) {
        const orderItem = this.$store.state.order.orderItems[i];

        result.push({
          quantity: orderItem.quantity,
          status: "x",
          ean: orderItem.product.ean,
          title: orderItem.product.title,
          generateLabelButton: orderItem.product.ean,
          photo: util.getDefaultThumbUrl(orderItem.product.images)
        })
      }
      return result
    },
  },
  methods: {
    getColor(item) {
      if (item.scanned < item.quantity) {
        return "secondary"
      } else if (item.scanned === item.quantity) {
        return "green"
      }
      return "red"
    },
    getIcon(item) {
      if (item.scanned < item.quantity) {
        return "mdi-check-circle"
      } else if (item.scanned === item.quantity) {
        return "mdi-check-circle"
      }
      return "mdi-alert-circle"
    },
    showIcon(item) {
      if (item.scanned === item.quantity
          || (item.quantity > 1 && item.scanned > 0)) {
        return true
      }
      return false
    },
    generateLabel(ean) {
      let payload = {ean: util.padEAN(ean), quantity: 1};
      this.$store.dispatch("createLabelAndPrint", payload)
    }
  }
}
</script>

<style scoped>
.counter {
  font-size: 150%;
}

.counter-red {
  font-size: 150%;
  color: #ff5252;
  font-weight: bold;
}
</style>